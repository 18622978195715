<template>
  <div>
    <breadcrumbs
      :breadcrumb-items="breadcrumbItems"
    />
    <b-card>
      <PartnerToolbar
        v-if="Object.keys(partner).length !== 0"
        :partner="partner"
      />
      <hr>
      <div class="table-filters">
        <div class="col-type">
          <b-form-radio-group
            id="type-report"
            v-model="typeStats"
            class="mb-1"
            button-variant="outline-primary"
            :options="optionsRadioLocale"
            buttons
            size="sm"
            name="radios-btn-default"
            @change="changeType"
          />
        </div>
        <div v-if="authUserData.role.includes('admin')">
          <b-button
            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
            variant="primary"
            size="sm"
            class="btn-icon ml-25"
            :to="{ name: 'dashboard-partner-stats-v2' }"
          >
            V2
          </b-button>
        </div>
      </div>
      <div
        v-if="loading"
        class="d-flex justify-content-center mb-1"
      >
        <b-spinner label="Loading..." />
      </div>
      <div v-else>
        <b-table
          small
          :items="rows"
          :fields="fields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          responsive
          striped
          foot-clone
        >
          <template #head(datetime)>
            {{ $t('message.tableHeader.date') }}
          </template>

          <template #head(impressions)>
            {{ $t('message.tableHeader.impressions') }}
          </template>

          <template #head(clicks)>
            {{ $t('message.tableHeader.clicks') }}
          </template>

          <template #head(ecpm)>
            {{ $t('message.tableHeader.ecpm') }}
          </template>

          <template #cell(datetime)="data">
            {{ getFormattedDate(new Date(data.value)) }}
          </template>

          <template #cell(impressions)="data">
            {{ data.value ? data.value : 0 }}
          </template>

          <template #cell(clicks)="data">
            {{ data.value ? data.value : 0 }}
          </template>

          <template #cell(ecpm)="data">
            {{ currency('USD', data.value) }}
          </template>

          <template #foot(datetime)="data">
            <strong :data-label="data.label">{{ $t('Records') }}: {{ rows.length }}</strong>
          </template>

          <template #foot(impressions)="data">
            <strong :data-label="data.label">{{ totalFormat('impressions').toLocaleString('Ru-ru') }}</strong>
          </template>

          <template #foot(clicks)="data">
            <strong :data-label="data.label">{{ totalFormat('clicks').toLocaleString('Ru-ru') }}</strong>
          </template>

          <template #foot(ecpm)="data">
            <strong :data-label="data.label">{{ currency('USD', totalAmount) }}</strong>
          </template>
        </b-table>
      </div>
    </b-card>
  </div>
</template>

<script>
import { getUserData } from '@/auth/utils'
import useJwt from '@/auth/jwt/useJwt'
import Ripple from 'vue-ripple-directive'
import {
  BCard,
  BTable,
  BFormRadioGroup,
  BSpinner,
  VBTooltip,
  BButton,
} from 'bootstrap-vue'
import Breadcrumbs from '@/views/ui/breadcrumbs/Breadcrumbs.vue'
import currencyFormatter from '@/utils/currency-formatter'
import getFormattedDate, { getFormattedDateTime } from '@/utils/date-formatter'
import PartnerToolbar from './PartnerToolbar.vue'

export default {
  components: {
    Breadcrumbs,
    BCard,
    BTable,
    BFormRadioGroup,
    BSpinner,
    PartnerToolbar,
    BButton,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      loading: true,
      pageTitle: '',
      pageSubtitle: '',
      breadcrumbItems: [],
      typeStats: '49',
      partner: {},
      rows: [],
      banner: [],
      fields: [
        { key: 'datetime', label: 'Date' },
        { key: 'impressions', label: 'Impressions' },
        { key: 'clicks', label: 'Clicks' },
        { key: 'ecpm', label: 'Revenue' },
      ],
      sortBy: 'datetime',
      sortDesc: true,
      sortDirection: 'asc',
      currency: currencyFormatter,
      getFormattedDate,
      getFormattedDateTime,
      authUserData: getUserData(),
      optionsRadio: [
        { text: '7 weeks', key: '7week', value: '49' },
        { text: '20 weeks', key: '20week', value: '140' },
      ],
    }
  },
  computed: {
    optionsRadioLocale() {
      return this.optionsRadio.map(o => ({
        value: o.value,
        text: this.$t(`stats.${o.key}`),
      }))
    },
    totalAmount() {
      let totalAmount = 0
      this.rows.forEach(i => {
        totalAmount += i.ecpm
      })
      return totalAmount
    },
  },
  created() {
    this.getPartnerData()
  },
  methods: {
    totalFormat(field) {
      let total = 0
      this.rows.forEach(i => {
        total += i[field]
      })
      return total
    },
    async setBreabcrumbs() {
      this.breadcrumbItems = [
        {
          text: this.$t('RTB partners'),
          to: '/partners/',
        },
        {
          text: this.partner.name,
          to: `/partners/${this.partner._id}/`,
        },
        {
          text: this.$t('Statistics'),
          active: true,
        },
      ]
    },
    async getPartnerData() {
      const responseData = await useJwt.getPartner(this.$route.params.id)
      this.partner = responseData.data.partner || {}
      this.getPartnerStatsByType()
      this.setBreabcrumbs()
    },
    async getPartnerStatsByType() {
      this.loading = true
      this.rows = null

      const urlSearchParams = new URLSearchParams(window.location.search)
      const urlParams = Object.fromEntries(urlSearchParams.entries())
      if (urlParams.type) {
        this.typeStats = urlParams.type
      }

      const params = {
        from: getFormattedDate(new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)),
        to: getFormattedDate(new Date()),
        partner_id: this.$route.params.id,
        type: this.typeStats,
      }
      const responseData = await useJwt.getPartnerStatsByType(params)
      this.rows = responseData.data.partnerstats || []
      this.loading = false
    },
    changeType() {
      this.$router.replace({
        name: 'dashboard-partner-stats',
        query: { type: this.typeStats },
      })
      this.getPartnerStatsByType()
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
<style>
  .table-filters {
    display: flex;
    justify-content: space-between;
  }

  tfoot th {
    background-color: #fff!important;
  }

  .col-picker {
    min-width: 220px;
  }

  @media (max-width: 1400px) {
    .table-filters {
      flex-wrap: wrap;
      margin-bottom: 15px;
    }

    .col-type {
      width: 60%;
    }
  }

  @media (max-width: 992px) {
    .col-type {
      width: 70%;
    }
  }

  @media (max-width: 800px) {
    .col-type {
      width: 100%;
    }
  }
</style>
